<script>
import {
  required,
  helpers
} from "@vuelidate/validators";
import userStore from '@/state/store'

export default {
  page: {
    title: "Login",
    meta: [{
      name: "description",
      content: '',
    },],
  },
  data() {
    return {
      username: "",
      password: "",
      authError: '',
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Username is required", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {},
  methods: {
    async login() {
      this.tryingToLogIn = true
      this.authError = '';
      await userStore.dispatch('login', {
        username: this.username,
        password: this.password
      })
      this.tryingToLogIn = false
      if (userStore.state.user.errors.length === 0) {
        await userStore.dispatch('verifyToken')
        await this.$router.push({
          path: this.$route.query.redirect ? this.$route.query.redirect : '/'
        });
      } else {
        this.tryingToLogIn = false
        this.password = ''
        this.authError = userStore.state.user.errors.join('\n')
      }
    }
  },
  mounted() {
    if (this.$refs.usernameInput) {
      this.$refs.usernameInput.focus()
    }
  }
};
</script>

<template>
  <!-- Section: Design Block -->
  <section class="d-flex justify-content-center align-items-center min-vh-100 auth-one-bg ">
    <!-- Jumbotron -->
    <div class="px-4 py-5 px-md-5 text-start">
      <div class="container">
        <div class="row gx-lg-5 align-items-center">
          <div class="col-lg-6 mb-5 mb-lg-0 d-none d-lg-block">

            <h4 class="mb-3" style="color: rgb(23,162,184);font-family: sans-serif">
              <strong>OUR PROMISES</strong>
            </h4>
            <h1 class="mb-5 display-3 fw-bold ls-tight">
              <span class="text-light d-block" style="font-family: sans-serif">Loyalty</span>
              <span class="text-light d-block" style="font-family: sans-serif">Integrity</span>
              <span class="text-light d-block" style="font-family: sans-serif">Respect</span>
            </h1>

            <p class="text-muted fs-5">
              <strong style="color: rgb(23,162,184)">InterRail Central Asia</strong> is part of the <strong
                style="color: rgb(23,162,184)">InterRail Group</strong> , an international transport
              holding, with a focus on
              rail
              freight.
            </p>
          </div>

          <div class="col-lg-6 mb-5 mb-lg-0">
            <div class="card" style="border-radius: 20px">
              <div class="card-body text-start py-5 px-4 px-md-5 position-relative overflow-hidden">
                <h2 class="mb-4">
                  <strong>Sign in</strong>
                </h2>


                <!-- Info Alert -->
                <b-alert v-if="authError.length > 0" show variant="danger" class="border-0 " role="alert">
                  <i class="mdi mdi-alert-circle-outline fw-bold"></i>
                  {{ authError }}
                </b-alert>


                <form @submit.prevent="login()">

                  <!-- Username input -->
                  <div class="form-outline mb-4">
                    <label class="form-label" for="username">Username</label>
                    <input v-model="username" ref="usernameInput" type="text" id="username"
                           class="form-control form-control-lg mb-1"
                           placeholder="Enter username"/>
                    <span id="username" class="form-text text-muted fs-6">
                      Enter your username or email address.
                    </span>
                  </div>

                  <!-- Password input -->
                  <div class="form-outline mb-4">
                    <label class="form-label" for="password">Password</label>
                    <input v-model="password" type="password" id="password" class="form-control form-control-lg"
                           placeholder="Enter your password"/>
                  </div>

                  <!-- Submit button -->
                  <button
                      :disabled="tryingToLogIn || username.trim().length === 0 || password.trim().length === 0"
                      type="submit" class="btn btn-lg mb-4 text-light" style="background-color: rgb(23,162,184)">
                    Sign in
                    <i v-if="tryingToLogIn" class="mdi mdi-loading mdi-spin ms-1"></i>
                  </button>

                </form>

                <div class="sign-in-form-img">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Jumbotron -->
  </section>
</template>

<style>
</style>